<template>
  <div class="flex-row-fluid ml-lg-12">
    <div class="card card-custom card-stretch">
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">我的信息</h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">设置更多的信息</span>
        </div>
        <div class="card-toolbar">
          <button type="reset" class="btn btn-success" v-on:click="saveInfo">保存</button>
        </div>
      </div>
      <div class="container pt-3" style="max-width: 70%">
        <form class="form">
          <div class="card-body">
            <div class="form-group row align-items-center">
              <div class="col-lg-12 col-xl-9">
                <div role="alert" v-if="errors && errors.length > 0" v-bind:class="{ show: errors.length }" class="alert fade alert-danger">
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>
                <div role="alert" v-if="msgs && msgs.length > 0" v-bind:class="{ show: msgs.length }" class="alert fade alert-success">
                  <div class="alert-text" v-for="(msg, i) in msgs" :key="i">
                    {{ msg }}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label"><span class="text-danger">*</span>姓名：</label>
              <div class="col-lg-9 col-xl-6">
                <input class="form-control form-control-lg form-control-solid" type="text" v-model="user.userName"/>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">性别：</label>
              <div class="col-lg-9 col-xl-6">
                <select class="form-control form-control-lg form-control-solid" v-model="user.gender">
                  <option value="1">男</option>
                  <option value="2">女</option>
                </select>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">邮箱：</label>
              <div class="col-lg-9 col-xl-6">
                <input class="form-control form-control-lg form-control-solid" type="text" v-model="user.email"/>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">地址：</label>
              <div class="col-lg-9 col-xl-6">
                <input class="form-control form-control-lg form-control-solid" type="text" v-model="user.contractAdress"/>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">行业：</label>
              <div class="col-lg-9 col-xl-6">
                <input class="form-control form-control-lg form-control-solid" type="text" v-model="user.industry"/>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">网站</label>
              <div class="col-lg-9 col-xl-6">
                <input class="form-control form-control-lg form-control-solid" type="text" v-model="user.webUrl"/>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/core/services/store";
import {VERIFY_AUTH} from "@/core/services/store/auth.module";
export default {
  computed: {
    user: {
      get () {return this.$store.getters.currentUser;}
    }
  },
  data() {
    return {
      errors: [],
      msgs: [],
    };
  },
  methods: {
    saveInfo() {
      this.errors = [];
      this.msgs = [];
      if (this.user && this.user.recordId){
        if (!this.user.userName){
          this.errors.push("请注意必须填写姓名");
          return;
        }
        this.$axios.fetchPost("hr/user/saveInfo",this.user).then(result => {
          store.dispatch(VERIFY_AUTH, result.data);
          this.msgs.push("保存成功");
        }).catch(err => {console.log(err);});
      }else {
        this.errors.push("登录已过期，请刷新页面");
      }
    }
  }
}
</script>